/* General Page Styles */
.home-page {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.popcorn-emoji {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 32px;
  pointer-events: none;
  transform: translate(-50%, -50%);
  transition: transform 0.1s ease-in-out;
  z-index: 10000 !important; /* Ensure it stays on top of everything */
}

/* Header */
.header {
  background: transparent;
  width: 100%;
  padding: 15px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  box-sizing: border-box;
}

.logo {
  font-size: 2rem;
  color: #4f4f4f !important; /* Default dark grey */
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
  cursor: pointer;
}

.logo:hover {
  color: #6f6f6f !important; /* Lighter grey on hover */
}

/* Navigation Menu */
.nav-menu {
  display: flex;
  gap: 15px;
}

.nav-menu a {
  color: #4f4f4f !important; /* Default dark grey */
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s ease-in-out;
}

.nav-menu a:hover {
  color: #6f6f6f !important; /* Lighter grey on hover */
}

/* Light header class for dark sections */
.light-header .logo, 
.light-header .nav-menu a {
  color: #ffffff !important; /* White color for dark sections */
}

/* Section 1: Hero Section with Video */
.hero-section {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--white);
  overflow: hidden;
}

.hero-container {
  width: 95%;
  background: var(--white);
  border-radius: 30px;
  padding: 15px;
  box-sizing: border-box;
  border: none;
}

.video-frame {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 20px;
}

.hero-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1; /* Ensure video stays behind all other elements */
}

.hero-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.hero-content h1 {
  color: #ffffff !important; /* Changed to white */
  font-size: 7rem;
  font-weight: bold;
  text-shadow: 3px 3px 20px rgba(0, 0, 0, 0.7);
}

/* Section 2: About Section */
.about-section {
  width: 100%;
  padding: 40px 20px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background: url('../../public/images/gradient3.jpg') no-repeat center center fixed;
  background-size: cover;
  min-height: 60vh;
  box-sizing: border-box;
}

.about-content {
  max-width: 800px;
  margin: 0 20px;
  padding: 15px;
  border-radius: 10px;
}

.about-content h2 {
  font-size: 2.5rem;
  color: #ffffff; /* Changed to white */
  font-weight: bold;
  margin-bottom: 15px;
}

.about-content p {
  font-size: 1.2rem;
  color: #ffffff; /* Changed to white */
}


/* Section 3: Showcase Section */
.showcase-section {
  width: 100%;
  padding: 40px 20px;
  background: var(--white);
  color: #000;
}

.showcase-container {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  flex-wrap: wrap;
}

.video-item {
  flex: 1;
  min-width: 22%;
  border-radius: 20px; /* Added rounded corners */
  overflow: hidden;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.video-item video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure all videos fill their containers uniformly */
}

/* Section 4: Create and Share Section with Parallax Images */
.create-share-section {
  width: 100%;
  padding: 40px 20px;
  background: var(--white);
  color: #000;
  text-align: center;
}

.create-share-section h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.create-share-section p {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.parallax-container {
  position: relative;
  width: 100%;
  height: 100vh; /* Full height of the viewport */
  overflow: hidden;
}

.parallax-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px; /* Added rounded corners */
  background-position: center;
  background-size: cover;
  transition: transform 1s ease;
}

.parallax-image:hover {
  transform: scale(1.1);
}

/* Section 5: Sign Up Section */
.signup-section {
  width: 100%;
  padding: 40px 20px;
  background: url('../../public/images/gradient3.jpg') no-repeat center center fixed;
  background-size: cover;
  color: #fff;
  text-align: center;
}

.signup-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 15px;
}

.signup-item {
  flex: 1 1 45%; /* Two columns on desktop */
  max-width: 45%;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  padding: 15px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  margin: 10px 0;
}

/* Mobile layout */
@media (max-width: 768px) {
  .signup-item {
    flex: 1 1 100%; /* Full width on mobile */
    max-width: 100%;
  }
}

/* Section 6: Background Section */
.background-section {
  width: 100%;
  padding: 40px 20px;
  background: #ffffff; /* Set explicitly to white */
  text-align: center;
  color: #000000; /* Set text color to black */
}

.background-content h2 {
  font-size: 2.5rem;
  font-weight: bold;
}

.background-content p {
  font-size: 1.2rem;
}

/* Section 7: Contact Section */
.contact-section {
  width: 100%;
  padding: 40px 20px;
  background: #ffffff; /* Set explicitly to white */
  color: #000000; /* Set text color to black */
  text-align: left;
  border-radius: 15px;
  margin-top: 30px;
}

.contact-box {
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.1); /* Adjusted background */
}

.contact-box h2 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.contact-email {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 20px;
  background: #444;
  padding: 10px 20px;
  border-radius: 5px;
  display: inline-block;
  color: #fff;
}

.contact-columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 30px;
  margin-top: 30px;
}

.footer-column {
  flex: 1;
  min-width: 220px;
}

.footer-column h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 8px;
}

.footer-column ul li a {
  color: #000 !important; /* Changed to black explicitly */
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s ease-in-out;
}

.footer-column ul li a:hover {
  color: #a291d1;
}

.contact-form {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
}

.contact-form input[type="email"] {
  padding: 8px;
  width: calc(100% - 60px);
  border: 1px solid #000; /* Changed border color to black */
  border-radius: 5px;
  font-size: 0.9rem;
}

.contact-form button {
  background: #fffff;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background 0.3s ease-in-out;
}

.contact-form button:hover {
  background: #8c9ccf;
}

.contact-form .arrow {
  font-size: 1.3rem;
  line-height: 0;
  transform: translateY(2px);
  color: #000; /* Set arrow color to black */
}

/* Footer Section */
footer {
  background: #ffffff; /* Set explicitly to white */
  color: #000000; /* Set text color to black */
  padding: 20px;
  text-align: center;
  border-top: none; /* Ensure no line above the footer */
}

footer p {
  font-size: 0.9rem;
  margin: 0;
}

.footer-bottom {
  text-align: center;
  margin-top: 15px;
  font-size: 0.8rem;
  color: #000000; /* Set explicitly to black */
}