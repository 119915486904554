/* Root Styles */
:root {
  --background-gradient: #ffffff; /* Set to white */
  --purple-shade: #000000; /* Set to black */
  --blue-shade: #000000; /* Set to black */
  --white: #ffffff;
  --cream: #000000; /* Set text color to black */
}

/* Global Styles */
body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: 'Cormorant Garamond', serif;
  scroll-behavior: smooth;
  background: var(--background-gradient); /* Updated to ensure white background */
  color: var(--cream); /* Updated to ensure black text */
}

/* App Wrapper */
.App {
  position: relative;
  margin: 0;
  padding: 0;
}

/* Header */
.header {
  background: transparent; /* Set header background to transparent */
  width: 100%;
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  box-sizing: border-box;
}

.logo {
  font-size: 2.5rem;
  color: var(--cream);
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.logo:hover {
  color: var(--blue-shade);
}

/* Hamburger Menu */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.hamburger .line {
  width: 25px;
  height: 3px;
  background-color: var(--cream);
  margin: 4px 0;
  transition: 0.4s;
}

/* Navigation Menu */
.nav-menu {
  display: flex;
  gap: 20px;
  z-index: 999;
}

.nav-menu a {
  color: var(--cream);
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

.nav-menu a:hover {
  color: var(--purple-shade);
}

@media (max-width: 768px) {
  .hamburger {
    display: flex;
  }

  .nav-menu {
    display: none;
    position: fixed;
    top: 60px;
    right: 20px;
    background: var(--background-gradient);
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    border-radius: 8px;
    z-index: 999;
  }

  .nav-menu.open {
    display: flex !important;
  }
}

/* Parallax Effect */
.parallax {
  height: 100vh;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--cream);
  text-align: center;
}

/* Hero Section */
.hero-section {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-size: 3rem;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
}

/* About Section Video */
.about-section video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Video Grid */
.video-grid {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.video-grid video {
  width: 200px;
  height: auto;
}

/* Signup Section */
/* Signup Section */
.signup-section button {
  background-color: #f5f5f5 !important; /* Light grey/white background */
  color: #000 !important; /* Black text */
  border: none !important;
  padding: 10px 20px !important;
  font-size: 1rem !important;
  cursor: pointer !important;
  border-radius: 8px !important;
  transition: background 0.3s ease-in-out !important;
  display: inline-block !important;
  width: 100% !important; /* Full-width on mobile */
  max-width: 300px; /* Limit width on larger screens */
  text-align: center;
}

/* Hover effect */
.signup-section button:hover {
  background-color: #e0e0e0 !important; /* Slightly darker grey */
}

/* Mobile-specific override */
@media only screen and (max-width: 768px) {
  .signup-section button {
    width: 100% !important; /* Full width for smaller screens */
    max-width: none !important;
    margin: 10px 0 !important; /* Spacing for mobile layout */
  }
}


/* Footer */
footer {
  background: transparent;
  color: var(--cream);
  padding: 30px 20px;
  text-align: center;
  border-top: 2px solid var(--purple-shade); /* Accent line at the top of the footer */
}

footer a {
  color: var(--purple-shade);
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease-in-out;
}

footer a:hover {
  color: var(--blue-shade);
}

.footer-content {
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-logo {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

/* Custom Cursor Styling */
.custom-cursor {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: fixed;
  pointer-events: none;
  z-index: 1000;
  mix-blend-mode: difference;
  background-color: var(--cream);
  transition: background-color 0.2s ease;
}
